import React, { useEffect } from "react";
import { Col, Container, Row, Form } from "react-bootstrap"; // Add Form import
import { useTranslation } from "react-i18next";
import { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../../Loader";

const ChatHistory = () => {
  const { id } = useParams();
  const [name, setName] = useState();
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [sessionList, setSessionList] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const [sessionSelectedId, setSessionSelectedId] = useState();
  const [messages, setMessage] = useState([]);
  const [daysAgo, setDaysAgo] = useState(10);
  const [isFirst, setIsFirst] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [topHeader, setTopHeader] = useState("#05204a");
  const [topHeaderFont, setTopHeaderFont] = useState("#05204a");
  const [questionBubble, setQuestionBubble] = useState();
  const [questionBubbleFont, setQuestionBubbleFont] = useState();
  const [answerBubble, setAnswerBubble] = useState("#05204a");
  const [answerBubbleFont, setAnswerBubbleFont] = useState("#05204a");
  const [sendButton, setSendButton] = useState("#05204a");
  const today = new Date();
  const calculateDate = new Date();
  calculateDate.setDate(today.getDate() - daysAgo);

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);

      if (localStorage.getItem("isRole") === "admin") {
        setDaysAgo(30);
      }

      setLoading(true);

      axios
        .get(`${API_URL}/${API_VERSION}/bot/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setLoading(false);
          setName(response.data.chatInterface.name);
          setTopHeader(response.data.chatInterface.topHeader);
          setTopHeaderFont(response.data.chatInterface.topHeaderFont);
          setAnswerBubble(response.data.chatInterface.answerBubble);
          setAnswerBubbleFont(response.data.chatInterface.answerBubbleFont);
          setQuestionBubble(response.data.chatInterface.questionBubble);
          setQuestionBubbleFont(response.data.chatInterface.questionBubbleFont);
          setSendButton(response.data.chatInterface.sendButton);
        })
        .catch((err) => {
          // console.log(err);
          setLoading(false);
        });

      axios
        .get(`${API_URL}/${API_VERSION}/bot/chatHistory/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const msg = response.data.messages.map((msg) => msg);

          setMessage(msg);
          setLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setLoading(false);
        });
    }

    axios
      .get(`${API_URL}/${API_VERSION}/bot/message-session/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setSessionList(response.data);
        if (response.data.length > 0) {
          setSessionSelectedId(response.data[0].session_id);
          fetchSessionData(response.data[0].session_id);
        }
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  }, [API_URL, API_VERSION, id, isFirst]);

  const fetchSessionData = (selectedId) => {
    // Fetch session data based on the selected session ID
    axios
      .get(`${API_URL}/${API_VERSION}/bot/session-msg/${id}/${selectedId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const msg = response.data[0].messages.map((msg) => msg);

        // Store the response in messageList
        setMessageList(msg);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  // Handle session selection change
  const handlerChange = (event) => {
    const selectedId = event.target.value;
    setSessionSelectedId(selectedId);
    // Fetch session data for the selected session
    fetchSessionData(selectedId);
  };
  // Add handlerChange function for the dropdown select

  const formatMessageDate = (timestamp) => {
    const messageDate = new Date(timestamp);

    return messageDate.toLocaleString();
  };

  const renderMessageText = (text) => {
    let __msg__ = text.replace(/\n/g, "<br>");
    // __msg__ = __msg__ + messageText.replace(/\n/g, "<br>");
    const urlRegex = /(https?:\/\/[^\s,;'"`<>\])}]+)/g;
    const parts = __msg__.split(urlRegex);
    let ___msg___ = "";
    parts.map((part, index) => {
      if (part.match(urlRegex)) {
        ___msg___ += `
                <a
                  style="color: ${answerBubbleFont}"
                  target="_blank"
                  rel="noopener noreferrer"
                  href=${part}
                >
                  ${part}
                </a>
                `;
      } else {
        ___msg___ += `${part}`;
      }
    });
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: ___msg___,
        }}
      ></span>
    );
  };

  return (
    <div>
      <Container>
        <Row className="justify-content-center">
          <Col md={12}>
            <Form.Group className="mb-3">
              <Form.Label>{t("Select Session id")}</Form.Label>
              <Form.Select
                type="text"
                value={sessionSelectedId}
                onChange={handlerChange}
              >
                {sessionList.map((id) => (
                  <option value={id.session_id} key={id.session_id}>
                    {id.session_id} - {new Date(id.createdAt).toLocaleString()}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </Container>
      {isLoading ? (
        <Loader />
      ) : (
        <section className="chating_box">
          <Container>
            <Row className="justify-content-center mt-3">
              <Col md={12}>
                <div className="chatingwrap">
                  <div
                    className="chating_head"
                    style={{ background: topHeader }}
                  >
                    <div className="brand" style={{ color: topHeaderFont }}>
                      {t(name)}
                    </div>
                    <div className="refresh">
                      <i className="fa-solid fa-rotate"></i>
                    </div>
                  </div>
                  <div className="chating_area">
                    {messageList &&
                      messageList.map((msg, index) => {
                        if (msg.data !== null) {
                          const isUserMessage = msg.from === "user";
                          const bubbleStyle = isUserMessage
                            ? {
                                background: questionBubble,
                                color: questionBubbleFont,
                              }
                            : {
                                background: answerBubble,
                                color: answerBubbleFont,
                              };

                          return (
                            <ul key={index}>
                              <li
                                className={isUserMessage ? "repaly" : "sender"}
                                style={bubbleStyle}
                              >
                                <div className="cont">
                                  <span>
                                    {renderMessageText(msg.data.text)}{" "}
                                  </span>
                                </div>
                              </li>
                              <small
                                style={{
                                  marginTop: "-10px",
                                  display: "block",
                                  textAlign: isUserMessage ? "right" : "left",
                                  float: "right",
                                }}
                              >
                                {formatMessageDate(msg.ts)}
                              </small>
                            </ul>
                          );
                        } else {
                          return null;
                        }
                      })}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </div>
  );
};

export default ChatHistory;
