import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../../Loader";
import MsgLoading from "../../MsgLoading";
import ReactTost from "../../ReactTost";
const ChatbotData = (props) => {
  const[preferLanguage, setPreferLanguage]=useState();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageLimitError, setMessageLimitError] = useState("");
  const { id } = useParams();
  const [botDeactivateMsg, setBotDeactivateMsg] = useState("");
  const [canSendMessage, setCanSendMessage] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const chatContainerRef = useRef(null);
  const chatMessageRef = useRef(null);
  const [type, setType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [mesg, setMesg] = useState("");
  const [messageType, setMessageType] = useState();
  const [sid, setSid] = useState();
  const [message, setMessage] = useState();
  const [chatHistoryData, setChatHistoryData] = useState([]);
  const [isFirst, setIsFirst] = useState(true);
  const [color, setColor] = useState("#05204a");
  const [limitMessage, setLimitMessage] = useState("");
  const [name, setName] = useState();
  const [initialMsg, setInitialMsg] = useState();
  const [messageToChat, setMessageToChat] = useState(false);
  const [suggestedQuestion, setSuggestedQuestion] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [topHeader, setTopHeader] = useState("#05204a");
  const [topHeaderFont, setTopHeaderFont] = useState("#05204a");
  const [questionBubble, setQuestionBubble] = useState("#05204a");
  const [questionBubbleFont, setQuestionBubbleFont] = useState("#05204a");
  const [answerBubble, setAnswerBubble] = useState("#05204a");
  const [answerBubbleFont, setAnswerBubbleFont] = useState("#05204a");
  const [sendButton, setSendButton] = useState("#05204a");
  const [perUserErrorDisplay, setPerUserErrorDisplay] = useState(false);
  const [perUserError, setPerUserError] = useState("");
  const [messageTime, setMessageTime] = useState(0);
  const renderLink = ({ attributes, content }) => {
    const { href, ...props } = attributes;
    return (
      <a to={href} {...props}>
        {content}
      </a>
    );
  };
  const options = {
    render: {
      hashtag: renderLink,
      mention: renderLink
    }
  };
  useEffect(() => {
    const getSid = () => {
      setIsLoading(true);
      axios
        .get(`${API_URL}/${API_VERSION}/bot/init/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((response) => {
          setIsLoading(false);
          if (response.data) {
            if (response.data.sid) {
              localStorage.setItem(id, response.data.sid);
              setSid(response.data.sid);
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    };

    if (id) {
      const sidList = localStorage.getItem(id);
      setSid(sidList);
      if (!sidList) {
        getSid();
      }
    }
  }, [API_URL, API_VERSION, id]);

  async function userQuestion() {
    let isNew = 1;
    let isKey = chatHistoryData.length;
    let __msg__ = "";
    await fetchEventSource(
      `${process.env.REACT_APP_STRING_CHAT_URL}/bot/query-stream/${id}?text=${message}&sid=${sid}`,
      {
        method: "GET",
        headers: {
          Accept: "text/event-stream"
        },
        onopen(res) {
          if (res.ok && res.status === 200) {
            console.log("Connection made ", res);
          } else if (
            res.status >= 400 &&
            res.status < 500 &&
            res.status !== 429
          ) {
            console.log("Client side error ", res);
          }
        },
        onmessage(event) {
          const messageText = JSON.parse(event.data).token;
          if (messageText !== "[END]") {
            __msg__ = __msg__ + messageText.replace(/\n/g, "<br>");
            // const urlRegex = /(https?:\/\/[^\s]+)/g;
            // const parts = __msg__.split(urlRegex);
            // let ___msg___ = "";
            // parts.map((part, index) => {
            //   if (part.match(urlRegex)) {
            //     let cleanedPart = "";
            //     if (part.endsWith(",")) {
            //       cleanedPart = part.replace(/,$/, "");
            //     } else {
            //       cleanedPart = part.replace(/.$/, "");
            //     }
            //     ___msg___ += `
            //     <a
            //       href=${part}
            //       target="_blank"
            //       rel="noopener noreferrer"
            //     >
            //       ${cleanedPart}
            //     </a>
            //     `;

            //     ___msg___ += part.endsWith(",") ? "," : ".";
            //   } else {
            //     ___msg___ += `${part}`;
            //   }
            // });

            const userMessage = (
              <li
                className="sender chatGPTreplay"
                style={{ background: answerBubble, color: answerBubbleFont }} // Set background and font color for user messages
              >
                <div className="cont">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: __msg__
                    }}
                  ></span>
                </div>
              </li>
            );
            if (isNew) {
              isNew = false;
              setChatHistoryData((prevHistory) => [
                ...prevHistory,
                userMessage
              ]);
            } else {
              setChatHistoryData((prevHistory) => {
                const updatedHistory = [...prevHistory];
                updatedHistory[parseInt(isKey + 1)] = userMessage;
                return updatedHistory;
              });
            }
          }
        },
        onclose() {
          console.log("Connection closed by the server");
        },
        onerror(err) {
          console.log("There was an error from server", err);
        }
      }
    );
  }
  async function CheckUSerMessageLimit() {
    try {
      if (sid) {
        const messageCountResponse = await axios.post(
          `${API_URL}/${API_VERSION}/bot/checkMessageLimit/${id}`,
          {
            sID: sid,
            time: messageTime
          }
        );

        return messageCountResponse.data.ans;
      } else {
        return false;
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.log(error.response.data.message);
        setType("Danger");
        setMesg(error.response.data.message);
        setShowAlert(true);
      }
    }
  }
  async function callQuestion(questionBubble, questionBubbleFont) {
    // setTimeout(() => {
    chatMessageRef.current.value = "";
    // chatMessageRef.current.focus();
    // }, 1000);
    // setIsDisable(() => true);
    // if (sid && message) {
    //   const userMessage = (
    //     <li className="repaly userInput" style={{ background: questionBubble }}>
    //       <div className="cont" style={{ color: questionBubbleFont }}>
    //         <span>{message}</span>
    //       </div>
    //     </li>
    //   );
    //   setChatHistoryData((prevHistory) => [...prevHistory, userMessage]);
    //   // setIsLoading(true);
    //   setMessageToChat(false);
    //   await userQuestion();
    //   setIsDisable(() => false);
    // }
    setIsDisable(true);
    await CheckUSerMessageLimit()
      .then(async (response) => {
        setPerUserErrorDisplay(false);
        if (response) {
          if (sid && message) {
            const userMessage = (
              <li
                className="repaly userInput"
                style={{ background: questionBubble }}
              >
                <div className="cont" style={{ color: questionBubbleFont }}>
                  <span>{message}</span>
                </div>
              </li>
            );
            setChatHistoryData((prevHistory) => [...prevHistory, userMessage]);
            setMessageToChat(false);
            await userQuestion(answerBubble, answerBubbleFont);
            setIsDisable(() => false);
          }
        } else {
          setPerUserErrorDisplay(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function sendMessageAndUpdateChat(messageText, background, textColor) {
    if (messageText && sid) {
      const userMessage = (
        <li
          className="repaly userInput"
          style={{ background, color: textColor }}
        >
          <div className="cont">
            <span>{messageText}</span>
          </div>
        </li>
      );
      setChatHistoryData((prevHistory) => [...prevHistory, userMessage]);
      chatMessageRef.current.value = "";
      // chatMessageRef.current.focus();
      setMessageToChat(false);
      await userQuestion(messageText);
      setIsDisable(false);
    }
  }

  async function handleKeyPress(e) {
    e.preventDefault();
    if (botDeactivateMsg) {
      return;
    }
    if (message.length > 2000) {
      setMessageLimitError("Please write a short message");
      return;
    }
    await CheckUSerMessageLimit().then(async (response) => {
      if (response) {
        setPerUserErrorDisplay(false);
        setMessageToChat(true);
        await sendMessageAndUpdateChat(
          message,
          questionBubble,
          questionBubbleFont
        );
      } else {
        setPerUserErrorDisplay(true);
      }
    });

    // chatMessageRef.current.blur();
    // setMessageToChat(true);
    // setMessage(e.target.value);
    // sendMessageAndUpdateChat(message, questionBubble, questionBubbleFont);
    // return false;
  }

  const Scroll = () => {
    const { offsetHeight, scrollHeight, scrollTop } = chatContainerRef.current;
    if (scrollHeight <= scrollTop + offsetHeight + 100) {
      chatContainerRef.current?.scrollTo(0, scrollHeight);
    }
  };
  useEffect(() => {
    Scroll();
  }, [chatHistoryData]);

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    }
    setLoading(true);
    axios
      .get(`${API_URL}/${API_VERSION}/bot/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then((response) => {
        setLoading(false);
        if (response.data.isBotActive !== "1") {
          setBotDeactivateMsg("Bot is not active");
          setCanSendMessage(false);
        } else {
          setCanSendMessage(true);
        }
        const msg = response.data.chatInterface.initialMsg;
        setInitialMsg(msg);
        setLimitMessage(response.data.settings.limitMessage);
        setMessageTime(response.data.settings.messageLimit);
        setPreferLanguage(response.data.settings.preferLanguage)
        setTopHeader(response.data.chatInterface.topHeader);
        setTopHeaderFont(response.data.chatInterface.topHeaderFont);
        setQuestionBubble(response.data.chatInterface.questionBubble);
        setQuestionBubbleFont(response.data.chatInterface.questionBubbleFont);
        setAnswerBubble(response.data.chatInterface.answerBubble);
        setAnswerBubbleFont(response.data.chatInterface.answerBubbleFont);
        setSendButton(response.data.chatInterface.sendButton);
        setName(response.data.chatInterface.name);
        setColor(response.data.chatInterface.color);
        if (response.data.chatInterface.suggestedMsg) {
          setSuggestedQuestion(
            response.data.chatInterface.suggestedMsg.split("\n")
          );
        }
        resetChatUi(
          msg,
          response.data.chatInterface.answerBubble,
          response.data.chatInterface.answerBubbleFont
        );
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    axios
      .get(`${API_URL}/${API_VERSION}/rateLimit`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then((response) => {
        console.log(response);
        if (response.data.length) {
          // setLimit(response.data[0].maxQuestion);
          // setSeconds(response.data[0].msgLimit);
          // setErrorDisplay(response.data[0].msgErr);

          // setUpdateId(response.data[0].id);
          if (localStorage.getItem("i18nextLng") === "de") {
            setPerUserError(response.data[0].anotherErr);
            console.log(response.data[0].anotherErr);
          } else {
            setPerUserError(response.data[0].msgErr);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [API_URL, API_VERSION, id, isFirst, perUserError]);

  function resetChatUi(msg, answerBubble, answerBubbleFont) {
    const _userMessage = (
      <li className="sender chatGPTreplay" style={{ background: answerBubble }}>
        <div className="cont" style={{ color: answerBubbleFont }}>
          <span>{msg}</span>
        </div>
      </li>
    );
    setChatHistoryData([]);
    setChatHistoryData((_prevHistory) => [..._prevHistory, _userMessage]);
  }
  useEffect(() => {
    if (messageToChat && message) {
      callQuestion();
    }
  }, [messageToChat, message]);
  const buttonStyle = {
    pointerEvents: isDisable ? "none" : "auto"
  };
  return (
    <>
      {botDeactivateMsg && <h3 style={{ color: "red" }}>{botDeactivateMsg}</h3>}
      {loading ? (
        <Loader />
      ) : (
        <section className="chating_box">
          <ReactTost
            onHide={() => {
              setShowAlert(false);
              setType("");
              setMesg("");
            }}
            alertClass={type}
            alertMessage={mesg}
            alertDo={showAlert}
          />
          <Container>
            <Row className="justify-content-center">
              <Col md={12}>
                <div className="chatingwrap">
                  <div
                    className="chating_head"
                    style={{ background: topHeader }}
                  >
                    <div className="brand" style={{ color: topHeaderFont }}>
                      {name}
                    </div>
                    <div
                      className="refresh"
                      onClick={() =>
                        resetChatUi(initialMsg, answerBubble, answerBubbleFont)
                      }
                    >
                      <i className="fa-solid fa-rotate"></i>
                    </div>
                  </div>
                  <div className="chating_area" ref={chatContainerRef}>
                    {loading ? <Loader /> : <ul>{chatHistoryData}</ul>}
                  </div>

                  <div className="sugbox">
                    {suggestedQuestion &&
                      suggestedQuestion.map((question, index) => {
                        return (
                          // <span
                          //   key={index}
                          //   onClick={(e) => {
                          //     setMessageToChat(true);
                          //     setMessage(e.target.textContent);
                          //     sendMessageAndUpdateChat(
                          //       e.target.textContent,
                          //       questionBubble,
                          //       questionBubbleFont
                          //     );
                          //   }}
                          // >
                          //   {question}
                          // </span>
                          <span
                            onClick={(e) => {
                              if (botDeactivateMsg) {
                                // If bot is not active, do not send the message
                                return;
                              }
                              CheckUSerMessageLimit()
                                .then(async (response) => {
                                  if (response) {
                                    setMessageToChat(true);
                                    setMessage(e.target.textContent);
                                    sendMessageAndUpdateChat(
                                      e.target.textContent,
                                      questionBubble,
                                      questionBubbleFont
                                    );
                                  } else {
                                    setPerUserErrorDisplay(true);
                                  }
                                })
                                .catch((error) => {
                                  console.log(error);
                                });
                            }}
                          >
                            {question}
                          </span>
                        );
                      })}
                    {perUserErrorDisplay && (
                      <div className="text-danger">
                        {limitMessage ? limitMessage : perUserError}
                      </div>
                    )}
                  </div>
                  {isLoading ? (
                    <MsgLoading />
                  ) : (
                    <div className="chating_foot">
                      <form onSubmit={(e) => handleKeyPress(e)}>
                        <input
                          type="text"
                          className="form-control"
                          aria-label="message…"
                          placeholder={messageType}
                          ref={chatMessageRef}
                          disabled={isDisable}
                          onInput={(e) => setMessage(e.target.value)}
                        />
                        <button
                          style={{ background: sendButton }}
                          type="button"
                          className="button"
                          onClick={(e) =>
                            callQuestion(questionBubble, questionBubbleFont)
                          }
                          disabled={!canSendMessage}
                          // onKeyDown={handleKeyPress}
                        >
                          <i
                            className="fa fa-paper-plane"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </form>
                      {messageLimitError && <div> {messageLimitError}</div>}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default ChatbotData;
