import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import UserFormSide from "./UserFormSide";
import UserLanguage from "./UserLanguage";
import TermsConditions from "./Modal/TermsConditions";
import axios from "axios";
import ReactTost from "../ReactTost";
import Loader from "../Loader";
import { changeLanguage } from "i18next";

const UserRegister = () => {
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [website, setWebsite] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [condition, setCondition] = useState(false);

  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({
    name: "",
    phone: "",
    email: "",
    company: "",
    website: "",
    password: "",
    confirmPassword: "",
    condition: "",
  });

  const navigate = useNavigate();
  const language =
    localStorage.getItem("i18nextLng") === "de" ? "german" : "english";

  function saveUser(e) {
    //setLoading(true);
    e.preventDefault();
    const errors = {};

    if (!name) {
      errors.name = t("Name is required.");
    }
    if (!phone) {
      errors.phone = t("Phone is required.");
    }
    if (!email) {
      errors.email = t("Email is required.");
    }
    if (!company) {
      errors.company = t("Company is required.");
    }
    if (!website) {
      errors.website = t("Website is required.");
    }
    if (!password) {
      errors.password = t("Password is required.");
    }
    if (!confirmPassword) {
      errors.confirmPassword = t("Confirm Password is required.");
    }
    if (!condition) {
      errors.condition = t("You must agree to the terms and conditions.");
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    if (password !== confirmPassword) {
      setType("Danger");
      setMessage("Password does not match.");
      setShowAlert(true);
      return;
    }

    setLoading(true);
    axios
      .post(`${API_URL}/${API_VERSION}/auth/register`, {
        name,
        phone,
        email,
        company,
        website,
        password,
      })
      .then((response) => {
        const language =
          localStorage.getItem("i18nextLng") === "de" ? "german" : "english";

        if (response.data.tokens.access.token) {
          axios
            .post(
              `${API_URL}/${API_VERSION}/auth/send-verification-email`,
              {
                language,
              },
              {
                headers: {
                  Authorization: `Bearer ${response.data.tokens.access.token}`,
                },
              }
            )
            .then((response) => {
              setLoading(false);
              setType("Success");
              setLoading(false);
              setMessage(t("Please check your email."));
              setShowAlert(true);
              setTimeout(() => {
                navigate("/");
              }, 3000);
            })
            .catch((error) => {
              setLoading(false);
              setType("Danger");
              setMessage(t("Something went wrong."));
              setShowAlert(true);
            });
        }
      })
      .catch((error) => {
        // console.log(error.response.data.message);
        setLoading(false);
        setType("Danger");
        setMessage(t(error.response.data.message));
        setShowAlert(true);
      });
  }
  function handleInputChange(field) {
    setFieldErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  }
  return (
    <>
      <UserLanguage claval="" />
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      {loading ? (
        <Loader />
      ) : (
        <div className="userform_wrap">
          <Container fluid>
            <Row className="align-items-center justify-content-between">
              <Col lg={6}>
                <UserFormSide pageTitle={t("Join us today!")} />
              </Col>
              <Col lg={6}>
                <section className="user_form">
                  <Container>
                    <Row className="justify-content-center">
                      <Col xl={10} lg={10} md={10}>
                        <div className="boxwrap p-md-5 p-4">
                          <h2>{t("Register")}</h2>
                          <form action="">
                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <label className="form-label">
                                    {t("Full name")} <span>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="fullName"
                                    name="fullName"
                                    onChange={(e) => {
                                      setName(e.target.value);
                                      handleInputChange("name");
                                    }}
                                  />
                                  {fieldErrors.name && (
                                    <div
                                      className="error"
                                      style={{ color: "red" }}
                                    >
                                      {fieldErrors.name}
                                    </div>
                                  )}
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <label for="" className="form-label">
                                    {t("Phone")} <span>*</span>
                                  </label>
                                  <input
                                    type="tel"
                                    className="form-control"
                                    id="phone"
                                    name="phone"
                                    onChange={(e) => {
                                      setPhone(e.target.value);
                                      handleInputChange("phone");
                                    }}
                                  />
                                  {fieldErrors.phone && (
                                    <div
                                      className="error"
                                      style={{ color: "red" }}
                                    >
                                      {fieldErrors.phone}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <label for="" className="form-label">
                                    {t("Email")} <span>*</span>
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    onChange={(e) => {
                                      setEmail(e.target.value);
                                      handleInputChange("email");
                                    }}
                                  />
                                  {fieldErrors.email && (
                                    <div
                                      className="error"
                                      style={{ color: "red" }}
                                    >
                                      {fieldErrors.email}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <label for="" className="form-label">
                                    {t("Company")}
                                    <span>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="company"
                                    name="company"
                                    onChange={(e) => {
                                      setCompany(e.target.value);
                                      handleInputChange("company");
                                    }}
                                  />
                                  {fieldErrors.company && (
                                    <div
                                      className="error"
                                      style={{ color: "red" }}
                                    >
                                      {fieldErrors.company}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col md={12}>
                                <div className="mb-3">
                                  <label for="" className="form-label">
                                    {t("Website")} <span>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="website"
                                    name="website"
                                    onChange={(e) => {
                                      setWebsite(e.target.value);
                                      handleInputChange("website");
                                    }}
                                  />
                                  {fieldErrors.website && (
                                    <div
                                      className="error"
                                      style={{ color: "red" }}
                                    >
                                      {fieldErrors.website}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col md={12}>
                                <div className="mb-3">
                                  <label for="" className="form-label">
                                    {t("Password")} <span>*</span>
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    onChange={(e) => {
                                      setPassword(e.target.value);
                                      handleInputChange("password");
                                    }}
                                  />
                                  <div id="" className="form-text">
                                    {t(
                                      "Your password must be 8-20 characters long,contain letters, numbers and special characters."
                                    )}
                                    {fieldErrors.password && (
                                      <div
                                        className="error"
                                        style={{ color: "red" }}
                                      >
                                        {fieldErrors.password}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Col>
                              <Col md={12}>
                                <div className="mb-3">
                                  <label for="" className="form-label">
                                    {t("Confirm password")} <span>*</span>
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    onChange={(e) => {
                                      setConfirmPassword(e.target.value);
                                      handleInputChange("confirmPassword");
                                    }}
                                  />
                                  {fieldErrors.confirmPassword && (
                                    <div
                                      className="error"
                                      style={{ color: "red" }}
                                    >
                                      {fieldErrors.confirmPassword}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col md={12}>
                                <div className="mb-3 form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="invalidCheck"
                                    name="condition"
                                    onChange={(e) => {
                                      setCondition(e.target.checked);
                                      handleInputChange("condition");
                                    }}
                                  />

                                  <label
                                    className="form-check-label"
                                    for="invalidCheck"
                                  >
                                    {t("I agree to ")}{" "}
                                    {/* Note the space added here */}
                                    <a
                                      href="javascript:void(0);"
                                      onClick={() => setModalShow(true)}
                                    >
                                      {t("terms and conditions")}
                                    </a>
                                    .
                                  </label>
                                  {fieldErrors.condition && (
                                    <div
                                      className="error"
                                      style={{ color: "red" }}
                                    >
                                      {fieldErrors.condition}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col md={12} className="text-start mb-4">
                                <input
                                  type="button"
                                  value={t("Sign up")}
                                  className="button px-md-4 py-md-3"
                                  onClick={(e) => saveUser(e)}
                                />
                              </Col>
                              <Col md={12} className="mt-3">
                                {t("Already have an account ?")}
                                <Link to="/">{t("Sign in")}</Link>
                              </Col>
                            </Row>
                          </form>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      <TermsConditions show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default UserRegister;
