import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import Loader from "../../Loader";
import ReactTost from "../../ReactTost";
const ChatBotSetting = (props) => {
  const { t } = useTranslation();
  const [temperature, setTemperature] = useState(0);
  const [preferLanguage, setPreferLanguage] = useState("English")
  const { id } = useParams();
  const [name, setName] = useState("");
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [prompt, setPrompt] = useState("");
  const [model, setModel] = useState("");
  const [domainError, setDomainError] = useState("");
  const[botIndexValue, setBotIndexValue] = useState('');
  const [limitMessage, setLimitMessage] = useState("");
  const [gptPrompt, setGptPrompt] = useState(`

      Information: {context}

      Question: {question}`);
  const [domain, setDomain] = useState("");
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [visibility, setVisibility] = useState("");
  const [messageCount, setMessageCount] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [setLoading, setIsLoading] = useState(false);
  const [messageLimit, setMessageLimit] = useState();
  const [chatgpt, setChatGpt] = useState(false);
  const [limit, setLimit] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [userId, setUserId] = useState();
  const handleTemperatureChange = (event) => {
    const newValue = parseFloat(event.target.value);
    setTemperature(newValue);
  };

  const handleChangePrefferLanguage = (e) => {
    setPreferLanguage(e.target.value); // Update state with the selected radio button value
    console.log(e.target.value);
  };

  const handleResetBasePrompt = () => {
    axios
      .get(`${API_URL}/${API_VERSION}/planInfo`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (localStorage.getItem("i18nextLng") === "de") {
          setPrompt(response.data[0].basePromptGerman);
        } else {
          setPrompt(response.data[0].basePromptEnglish);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  async function checkUrl(url) {
    if (url) {
      let ans = true;
      url = url.split("\n");
      url.forEach((u) => {
        if (u && !(u.startsWith("http://") || u.startsWith("https://"))) {
          ans = false;
        }
      });
      return ans;
    }
  }
  async function updateBot(e) {

    e.preventDefault();
    console.log(botIndexValue)
    if (visibility === "private") {
      const _checkDomain = await checkUrl(domain);
      if (!_checkDomain) {
        setDomainError(t("url must start with http or https"));
        return false;
      }
    }
    setIsLoading(true);
    axios
      .patch(
        `${API_URL}/${API_VERSION}/bot/${id}`,
        {
          name,
          settings: {
            prompt,
            model,
            temperature,
            visibility,
            domain,
            messageCount,
            messageLimit,
            gptPrompt,
            preferLanguage,
            limitMessage,
            chatBotIndexValue: botIndexValue
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setType("Success");
        setMessage("Setting updated successfully");
        showAlert(true);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }
  async function getUserActivePlan() {
    if(userId){
      await axios
      .get(`${API_URL}/${API_VERSION}/user/active-plan/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data.plan.chatgpt4);
      setChatGpt(response.data.plan.chatgpt4) 
      })
      .catch((error) => {
        setType("Danger");
        setMessage(t("something went wrong"));
        setShowAlert(true);
        return false;
      });
  
    }
   
}
  useEffect(() => {
    axios
      .get(`${API_URL}/${API_VERSION}/rateLimit`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data.length) {
          setLimit(response.data[0].maxQuestion);
          setSeconds(response.data[0].msgLimit);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(true);
    axios
      .get(`${API_URL}/${API_VERSION}/bot/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setUserId(response.data.user.id);
        setName(response.data.name);
        setModel(response.data.settings.model);
        setDomain(response.data.settings.domain);
        setTemperature(response.data.settings.temperature);
        setMessageLimit(response.data.settings.messageLimit);
        setPrompt(response.data.settings.prompt);
        setVisibility(response.data.settings.visibility);
        setPreferLanguage(response.data.settings.preferLanguage)
        setMessageCount(response.data.settings.messageCount);
        setLimitMessage(response.data.settings.limitMessage);
        setBotIndexValue(response.data.settings.chatBotIndexValue);
       
        if (response.data.settings.gptPrompt) {
          setGptPrompt(response.data.settings.gptPrompt);
        }
       
       
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
   
  }, []);
useEffect(()=>{
  if(userId){
    getUserActivePlan();
  }
 // getUserActivePlan();
},[userId])
 
 

  return (
    <>
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      {setLoading ? (
        <Loader />
      ) : (
        <section className="settings">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} className="mb-4">
                <div className="settings_wrap">
                  <form action="">
                    <div className="form-group chat_info">
                      <label className="form-label">{t("Chatbot ID")}</label>
                      <div className="idtext">
                        <p>{id}</p>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label">
                        {t("Name")}
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={<Tooltip>{t("name info")}</Tooltip>}
                        >
                          <span className="ms-2 text-primary">
                            <i class="fa-solid fa-circle-question"></i>
                          </span>
                        </OverlayTrigger>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="mt-3">

                      <Form.Group
                        className="mb-3"
                        controlId="CancelShuttleJetty"
                      >
                        <Form.Label>
                          {t("Choose preffered language")}
                        </Form.Label>
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={<Tooltip>{t("prefer language info")}</Tooltip>}
                        >
                          <span className="ms-2 text-primary">
                            <i class="fa-solid fa-circle-question"></i>
                          </span>
                        </OverlayTrigger>
                        <div>
                          <Form.Check
                            inline
                            type="radio"
                            id="cancelShuttleJettyYes"
                            label="English"
                            name="cancelShuttleJetty"
                            value="English"
                            onChange={handleChangePrefferLanguage}
                            checked={preferLanguage === "English"}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            id="cancelShuttleJettyNo"
                            label="Deutsch"
                            name="cancelShuttleJetty"
                            value="Deutsch"
                            onChange={handleChangePrefferLanguage}
                            checked={preferLanguage === "Deutsch"}
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        {t("ChatGPT prompt")}
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={<Tooltip>{t("ChatGPT prompt")}</Tooltip>}
                        >
                          <span className="ms-2 text-primary">
                            <i class="fa-solid fa-circle-question"></i>
                          </span>
                        </OverlayTrigger>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="gptPrompt"
                        id="gptPrompt"
                        value={gptPrompt}
                        onChange={(e) => setGptPrompt(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <label className="form-label mb-0">
                          {t("Base Prompt (system message)")}
                          <OverlayTrigger
                            key="right"
                            placement="right"
                            overlay={<Tooltip>{t("Base Prompt info")}</Tooltip>}
                          >
                            <span className="ms-2 text-primary">
                              <i class="fa-solid fa-circle-question"></i>
                            </span>
                          </OverlayTrigger>
                        </label>
                        <div
                          className="userbutton py-1 px-3"
                          onClick={() => handleResetBasePrompt()}
                        >
                          {t("RESET TO DEFAULT")}
                        </div>
                      </div>
                      <textarea
                        rows="3"
                        id="exampleForm.ControlTextarea1"
                        className="form-control"
                        spellcheck="false"
                        value={prompt}
                        onChange={(event) => setPrompt(event.target.value)}
                      ></textarea>
                    </div>

                    <div className="form-group">
                      <label className="form-label">
                        {t("Model")}
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={<Tooltip>{t("model info")}</Tooltip>}
                        >
                          <span className="ms-2 text-primary">
                            <i class="fa-solid fa-circle-question"></i>
                          </span>
                        </OverlayTrigger>
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={model}
                        onChange={(event) => setModel(event.target.value)}
                      >
                        <option selected value="gpt-3.5-turbo">
                          gpt-3.5-turbo
                        </option>
                        {chatgpt == true ? (
                          <option value="gpt-4o">gpt-4</option>
                        ) : (
                          ""
                        )}
                        
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="form-label">
                        {t("Temperature")}
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={<Tooltip>{t("Temperature info")}</Tooltip>}
                        >
                          <span className="ms-2 text-primary">
                            <i class="fa-solid fa-circle-question"></i>
                          </span>
                        </OverlayTrigger>
                      </label>
                      <strong style={{ float: "right" }}>{temperature}</strong>
                      <input
                        type="range"
                        className="form-range"
                        min="0"
                        max="1"
                        step="0.1"
                        id="customRange"
                        value={temperature}
                        onChange={(e) => handleTemperatureChange(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        {t("Visibility")}
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={<Tooltip>{t("Visibility info")}</Tooltip>}
                        >
                          <span className="ms-2 text-primary">
                            <i class="fa-solid fa-circle-question"></i>
                          </span>
                        </OverlayTrigger>
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={visibility}
                        onChange={(event) => setVisibility(event.target.value)}
                      >
                        <option value="public">public</option>
                        <option value="private">private</option>
                      </select>
                    </div>
                    {visibility && visibility === "private" && (
                      <div className="form-group">
                        <label className="form-label">
                          {t("Domains")}
                          <OverlayTrigger
                            key="right"
                            placement="right"
                            overlay={<Tooltip>{t("Domains info")}</Tooltip>}
                          >
                            <span className="ms-2 text-primary">
                              <i class="fa-solid fa-circle-question"></i>
                            </span>
                          </OverlayTrigger>
                        </label>
                        <textarea
                          rows="3"
                          id="exampleForm.ControlTextarea1"
                          className="form-control"
                          spellCheck="false"
                          value={domain}
                          onChange={(event) => {
                            setDomain(event.target.value);
                            setDomainError("");
                          }}
                        ></textarea>
                        <div className="text-danger">{domainError}</div>
                      </div>
                    )}

                    <div className="form-group">
                      <label className="form-label">
                        {t("Max Questions per visitor")}
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={
                            <Tooltip>
                              {t("Max Questions per visitor info")}
                            </Tooltip>
                          }
                        >
                          <span className="ms-2 text-primary">
                            <i class="fa-solid fa-circle-question"></i>
                          </span>
                        </OverlayTrigger>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="messageCount"
                        id="messageCount"
                        value={messageCount}
                        onChange={(e) => setMessageCount(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        {t("Limit Message")}
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={<Tooltip>{t("Please enter the message a user should receive if the message limit is reached")}</Tooltip>}
                        >
                          <span className="ms-2 text-primary">
                            <i class="fa-solid fa-circle-question"></i>
                          </span>
                        </OverlayTrigger>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        id="limit"
                        value={limitMessage}
                        onChange={(e) => setLimitMessage(e.target.value)}
                      />
                    </div>
                    {/* <div className="form-group">
                    <label className="form-label">
                      {t("Message Limit (second)")}
                      <OverlayTrigger
                        key="right"
                        placement="right"
                        overlay={<Tooltip>{t("name info")}</Tooltip>}
                      >
                        <span className="ms-2 text-primary">
                          <i class="fa-solid fa-circle-question"></i>
                        </span>
                      </OverlayTrigger>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="messageLimit"
                      id="messageLimit"
                      value={messageLimit}
                      onChange={(e) => setMessageLimit(e.target.value)}
                    />
                  </div> */}
 
                    {setLoading ? (
                      <Loader />
                    ) : (
                      <input
                        type="button"
                        className="userbutton"
                        value={t("SUBMIT")}
                        onClick={(e) => updateBot(e)}
                      />
                    )}
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default ChatBotSetting;
