import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import UserAddBot from "../Modal/UserAddBot";
import axios from "axios";
import ReactTost from "../../ReactTost";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";

const UserBots = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = localStorage.getItem("id");
  const [addModalShow, setAddModalShow] = useState(false);
  const [activeMessage, setActiveMessage] = useState("");
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;

  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [getBots, setGetBots] = useState(true);
  const [botList, setBotList] = useState([]);
  const [prompt, setPrompt] = useState();
  function checkingPlan() {
    setLoading(true);
    axios
      .get(`${API_URL}/${API_VERSION}/user/plan/${user}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then((response) => {
        setLoading(false);
        // console.log(response.data)
        if (response.data.bot === true) {
          setAddModalShow(true);
        } else {
          setType("Danger");
          setMessage(
            t(
              "Bot limit is exceeded on your plan, Please, upgrade your plan to create more bots"
            )
          );

          setShowAlert(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setType("Danger");
        setMessage(t("Something went wrong"));
        setShowAlert(true);
      });
  }
  console.log();
  function getPrompt() {
    axios
      .get(`${API_URL}/${API_VERSION}/planInfo`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then((response) => {
        if (localStorage.getItem("i18nextLng") === "en") {
          setPrompt(response.data[0].basePromptEnglish);
        } else {
          setPrompt(response.data[0].basePromptGerman);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  function createBot(name) {
    if (name && user) {
      setLoading(true);
      axios
        .put(
          `${API_URL}/${API_VERSION}/bot`,
          {
            name,
            user,
            isBotActive: "1",
            settings: {
              prompt: prompt,
              model: "gpt-3.5-turbo",
              temperature: 0,
              messageCount: 20,
              messageLimit: 100,
              visibility: "public",
           //   chatBotIndexValue:"100001",
              preferLanguage:
                localStorage.getItem("i18nextLng") === "de"
                  ? "Deutsch"
                  : "English",
              limitMessage:
                localStorage.getItem("i18nextLng") === "de"
                  ? "Das Frage-Limit für heute würde erreicht. Bitte den Support kontaktieren"
                  : "The question limit has been reached for today. Please contact our support."
            },
            chatInterface: {
              chatBotIndexValue:"100001",
              logoName: "../images/chat-icon-double-black-white.svg",
              btnAlign: "Bottom Right"
            }
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        )
        .then((response) => {
          setLoading(false);
          setAddModalShow(false);
          setType("Success");
          setMessage(t("Your bot is created."));
          setShowAlert(true);
          setGetBots(true);

          setTimeout(() => {
            navigate(`/user-chatbot/${response.data.botId}`);
          }, 3000);
        })
        .catch((error) => {
          setLoading(false);
          setType("Danger");
          setMessage(t("Something went to wrong."));
          setShowAlert(true);
        });
    }
  }

  useEffect(() => {
    const getAllBots = () => {
      setLoading(true);
      axios
        .get(`${API_URL}/${API_VERSION}/bot/user/${user}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((response) => {
          setLoading(false);
          setBotList(response.data);
          response.data.map((bot) => {
            switch (bot.isBotActive) {
              case 0:
                setActiveMessage("Your bot is not active");
                break;
              case "1":
                setActiveMessage("");
                break;
              case 2:
                setActiveMessage("This bot is diactivate by admin");
                break;
              default:
                break;
            }
          });
          // if (response.data.map((bot) => bot.isBotActive === "2")) {
          //   setActiveMessage("This bot is diactivate by admin");
          // } else if (response.data.map((bot) => bot.isBotActive === "0")) {
          //   setActiveMessage("Your bot is not active");
          // } else {
          //   setActiveMessage("");
          // }
          setGetBots(false);
        })
        .catch((error) => {
          setLoading(false);
          setType("Danger");
          setMessage(t("Something went to wrong."));
          setShowAlert(true);
        });
    };
    getPrompt();
    if (getBots) {
      getAllBots();
    }
  }, [user, API_URL, API_VERSION, getBots]);
  return (
    <>
      <section className="userbots_main">
        <ReactTost
          onHide={() => {
            setShowAlert(false);
            setType("");
            setMessage("");
          }}
          alertClass={type}
          alertMessage={message}
          alertDo={showAlert}
        />
        <Container>
          <Row>
            <Col md={12}>
              <div className="d-flex align-items-center justify-content-between mb-4">
                <div className="head_title">
                  <h1 className="mb-0">{t("My Chatbot")}</h1>
                </div>
                <Link className="userbutton" onClick={() => checkingPlan()}>
                  <i className="fa-solid fa-circle-plus"></i> {t("New Chatbot")}
                </Link>
              </div>
            </Col>
          </Row>
          {loading ? (
            <Loader />
          ) : (
            <Row>
              {botList &&
                botList.map((bot, index) => {
                  return (
                    <Col lg={4} md={6} className="mb-4">
                      {loading ? (
                        <Loader />
                      ) : (
                        <Link to={`/user-chatbot/${bot.id}`}>
                          <Card>
                            <div className="imgbox">
                              <Card.Img
                                variant="top"
                                src="../images/chatbot.gif"
                              />
                            </div>
                            <Card.Body>
                              <div className="infobox">
                                <div className="info">
                                  <strong>{t("Name")} :</strong>
                                  <span> {bot.name}</span>
                                </div>
                                {bot.isBotActive === "2" && (
                                  <div
                                    className="active-message"
                                    style={{ color: "red" }}
                                  >
                                    {t("Bot is deactivated by admin")}
                                  </div>
                                )}
                                {bot.isBotActive === "0" && (
                                  <div
                                    className="active-message"
                                    style={{ color: "red" }}
                                  >
                                    {t("Bot is not active")}
                                  </div>
                                )}
                              </div>
                            </Card.Body>
                          </Card>
                        </Link>
                      )}
                    </Col>
                  );
                })}
            </Row>
          )}
        </Container>
      </section>
      <UserAddBot
        show={addModalShow}
        onHide={() => setAddModalShow(false)}
        createBot={createBot}
      />
    </>
  );
};

export default UserBots;
