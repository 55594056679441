import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, Form } from "react-bootstrap";
import AddBot from "../Modal/AddBot";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReactTost from "../../ReactTost";
import Loader from "../../Loader";
const Bots = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isBotActive, setIsBotActive] = useState(false);
  const [getBots, setGetBots] = useState(true);
  const [botList, setBotList] = useState([]);
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  function checkingPlan() {
    setLoading(true);
    axios
      .get(`${API_URL}/${API_VERSION}/user/plan/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then((response) => {
        setLoading(false);
        console.log(response);
        if (response.data.bot === true) {
          setModalShow(true);
        } else {
          setType("Danger");
          setMessage(
            "Bot limit is exceeded on your plan, Please, upgrade your plan to create more bots"
          );
          setShowAlert(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setType("Danger");
        setMessage("something went wrong");
        setShowAlert(true);
      });
  }
  function createBot(name) {
    let user = id;
    if (name && user) {
      axios
        .put(
          `${API_URL}/${API_VERSION}/bot`,
          {
            name,
            user,
            isBotActive: "1",
            settings: {
              prompt: prompt,
              model: "gpt-3.5-turbo",
              temperature: 0,
              messageCount: 20,
              messageLimit: 100,
              visibility: "public",
              preferLanguage:"Engish",
              limitMessage:"The question limit has been reached for today. Please contact our support.",
              chatBotIndexValue:"100001",
            },
            chatInterface: {
             // chatBotIndexValue:"100001",
              logoName: "../images/chat-icon-double-black-white.svg",
              btnAlign: "Bottom Right"
            }
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        )
        .then((response) => {
          setModalShow(false);
          setGetBots(true);
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    }
  }

  function gotoBotSetting(id) {
    navigate(`/admin/chatbot/${id}`);
  }
  const getAllBots = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/${API_VERSION}/bot/user/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then((response) => {
        setBotList(response.data);
        console.log(response.data);

        setGetBots(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (getBots) {
      getAllBots();
    }
  }, [id, API_URL, API_VERSION, getBots]);
  function updateUser(e, id) {
    const isBotActive = e.target.checked;
    let value = "";
    if (isBotActive === true) {
      value = "1";
    } else {
      value = "2";
    }
    axios
      .patch(
        `${API_URL}/${API_VERSION}/bot/botStatus/${id}`,
        {
          isBotActive: value
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      )
      .then((response) => {
        setIsBotActive(isBotActive);
        getAllBots();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      <section className="bots_main">
        <ReactTost
          onHide={() => {
            setShowAlert(false);
            setType("");
            setMessage("");
          }}
          alertClass={type}
          alertMessage={message}
          alertDo={showAlert}
        />
        {loading ? (
          <Loader />
        ) : (
          <Container>
            <Row>
              <Col md={12}>
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div className="head_title">
                    <h1 className="mb-0">New Chatbot</h1>
                  </div>
                  <button className="button" onClick={() => checkingPlan()}>
                    <i className="fa-solid fa-circle-plus"></i> Add Bot
                  </button>
                </div>
              </Col>
            </Row>
            <Row>
              {botList &&
                botList.map((bot, index) => {
                  return (
                    <>
                      <Col
                        lg={4}
                        md={6}
                        className="mb-4"
                        // onClick={() => gotoBotSetting(bot.id)}
                        onClick={(e) => {
                          const isSwitchClicked = e.target.type === "checkbox";
                          if (!isSwitchClicked) {
                            gotoBotSetting(bot.id);
                          } 
                        }}
                        >
                        <Card>
                          <div className="imgbox">
                            <Card.Img
                              variant="top"
                              src="../../images/chatbot.gif"
                            />
                          </div>
                          <Card.Body>
                            <div className="card_body">
                              <div className="infobox">
                                <div className="info">
                                  <strong>Name :</strong>
                                  <span>{bot.name}</span>
                                </div>
                                {bot.isBotActive === "2" && (
                                  <div className="active-message" style={{ color: "red" }} >
                                    Bot is deactivated by admin
                                  </div>
                                )}
                                {bot.isBotActive === "0" && (
                                  <div className="active-message" style={{ color: "red" }} >
                                    Bot is not active
                                  </div>
                                )}
                              </div>
                              <div className="toggleBtn">
                                <Form>
                                  <Form.Check
                                    className="float-end"
                                    type="switch"
                                    defaultChecked={
                                      bot.isBotActive === "1" ? true : false
                                    }
                                    onChange={(e) => updateUser(e, bot.id)}
                                  />
                                </Form>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      {/* <Col>
                        <Form>
                          <Form.Check
                            className="float-end"
                            type="switch"
                            defaultChecked={
                              bot.isBotActive === "1" ? true : false
                            }
                            onChange={(e) => updateUser(e, bot.id)}
                          />
                        </Form>
                      </Col> */}
                    </>
                  );
                })}
            </Row>
          </Container>
        )}
      </section>
      <AddBot
        show={modalShow}
        onHide={() => setModalShow(false)}
        createBot={createBot}
      />
    </>
  );
};

export default Bots;
