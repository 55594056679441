import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader";
import ReactTost from "../../ReactTost";
const UserChatBotSetting = (props) => {
  const { t } = useTranslation();
  const id = localStorage.getItem("id");
  const [domainError, setDomainError] = useState("");
 // const[botIndexValue, setBotIndexValue] = useState('');
  const [temperature, setTemperature] = useState(0);
  const [isFirst, setIsFirst] = useState(true);
  const [chatgpt, setChatGpt] = useState(false);
  const [botId, setBotId] = useState(0);
  const [name, setName] = useState("");
  const API_URL = process.env.REACT_APP_API_URL;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const [prompt, setPrompt] = useState("");
  const [model, setModel] = useState("");
  const [domain, setDomain] = useState("");
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [visibility, setVisibility] = useState("");
  const [messageCount, setMessageCount] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [setLoading, setIsLoading] = useState(false);
  const [messageLimit, setMessageLimit] = useState();
  const [updateId, setUpdateId] = useState(0);
  const [limit, setLimit] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [errorDisplay, setErrorDisplay] = useState("");
  const [preferLanguage, setPreferLanguage] = useState("English");
  const [limitMessage, setLimitMessage] = useState("");
  const handleTemperatureChange = (event) => {
    const newValue = parseFloat(event.target.value);
    setTemperature(newValue);
  };

  const handleResetBasePrompt = () => {
    axios
      .get(`${API_URL}/${API_VERSION}/planInfo`, {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then((response) => {
        // console.log("LANG : ", localStorage.getItem("i18nextLng"));
        if (localStorage.getItem("i18nextLng") === "de") {
          setPrompt(response.data[0].basePromptGerman);
        } else {
          setPrompt(response.data[0].basePromptEnglish);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  async function checkUrl(url) {
    if (url) {
      let ans = true;
      url = url.split("\n");
      url.forEach((u) => {
        if (u && !(u.startsWith("http://") || u.startsWith("https://"))) {
          ans = false;
        }
      });
      return ans;
    }
  }
  async function updateBot(e) {
    e.preventDefault();
    if (visibility === "private") {
      const _checkDomain = await checkUrl(domain);
      if (!_checkDomain) {
        setDomainError(t("url must start with http or https"));
        return false;
      }
    }
    setIsLoading(true);
    // console.log(preferLanguage)
    axios
      .patch(
        `${API_URL}/${API_VERSION}/bot/${botId}`,
        {
          name,
          settings: {
            prompt,
            model,
            temperature,
            visibility,
            domain,
            messageCount,
            messageLimit,
            preferLanguage,
            limitMessage,
            //chatBotIndexValue: botIndexValue
          }
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      )
      .then((response) => {
        setIsLoading(false);
        setType("Success");
        // console.log(response);
        setMessage(t("Setting updated successfully"));
        showAlert(true);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }
  async function getUserActivePlan() {
    await axios
      .get(`${API_URL}/${API_VERSION}/user/active-plan/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then((response) => {
        if (response.data) {
          setChatGpt(response.data.plan);
        }
      })
      .catch((error) => {
        setType("Danger");
        setMessage(t("Something went wrong"));
        setShowAlert(true);
        return false;
      });
  }
  useEffect(() => {
    if (props.botData.length !== 0 && setIsFirst) {
      axios
        .get(`${API_URL}/${API_VERSION}/rateLimit`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        .then((response) => {
          if (response.data.length) {
            setLimit(response.data[0].maxQuestion);
            setSeconds(response.data[0].msgLimit);
            setErrorDisplay(response.data[0].msgErr);
            setUpdateId(response.data[0].id);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
      getUserActivePlan();
      setBotId(props.botData.id);
      setName(props.botData.name);
      if (props.botData.settings) {
        console.log(props.botData.settings);
        setTemperature(props.botData.settings.temperature);
        setPrompt(props.botData.settings.prompt);
        setVisibility(props.botData.settings.visibility);
        setModel(props.botData.settings.model);
        setDomain(props.botData.settings.domain);
        setMessageCount(props.botData.settings.messageCount);
        setMessageLimit(props.botData.settings.messageLimit);
        setPreferLanguage(props.botData.settings.preferLanguage);
      //  setBotIndexValue(props.botData.settings.chatBotIndexValue);
        setLimitMessage(props.botData.settings.limitMessage);
      }
      setIsFirst(false);
    }
  }, [props.botData, isFirst]);

  return (
    <>
      <ReactTost
        onHide={() => {
          setShowAlert(false);
          setType("");
          setMessage("");
        }}
        alertClass={type}
        alertMessage={message}
        alertDo={showAlert}
      />
      <section className="usersettings">
        <Container>
          <Row className="justify-content-center">
            <Col md={12} className="mb-4">
              <div className="settings_wrap">
                <form action="">
                  <div className="form-group chat_info">
                    <label className="form-label">{t("Chatbot ID")}</label>
                    <div className="idtext">
                      <p>{botId}</p>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-label">
                      {t("Name")}
                      <OverlayTrigger
                        key="right"
                        placement="right"
                        overlay={<Tooltip>{t("name info")}</Tooltip>}
                      >
                        <span className="ms-2 text-primary">
                          <i class="fa-solid fa-circle-question"></i>
                        </span>
                      </OverlayTrigger>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="mt-3">
                    <Form.Group className="mb-3" controlId="langRadio">
                      <Form.Label>{t("Choose preffered language")}</Form.Label>
                      <OverlayTrigger
                        key="right"
                        placement="right"
                        overlay={<Tooltip>{t("prefer language info")}</Tooltip>}
                      >
                        <span className="ms-2 text-primary">
                          <i class="fa-solid fa-circle-question"></i>
                        </span>
                      </OverlayTrigger>
                      <div>
                        <Form.Check
                          inline
                          type="radio"
                          id="EnglishRadio"
                          label="English"
                          name="langRadio"
                          value="English"
                          onChange={(e) => {
                            setPreferLanguage(e.target.value);
                          }}
                          checked={preferLanguage === "English"}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          id="DeutschRadio"
                          label="Deutsch"
                          name="langRadio"
                          value="Deutsch"
                          onChange={(e) => {
                            setPreferLanguage(e.target.value);
                          }}
                          checked={preferLanguage === "Deutsch"}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="form-group">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <label className="form-label mb-0">
                        {t("Base Prompt (system message)")}
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={<Tooltip>{t("Base Prompt info")}</Tooltip>}
                        >
                          <span className="ms-2 text-primary">
                            <i class="fa-solid fa-circle-question"></i>
                          </span>
                        </OverlayTrigger>
                      </label>
                      <div
                        className="userbutton py-1 px-3"
                        onClick={() => handleResetBasePrompt()}
                      >
                        {t("RESET TO DEFAULT")}
                      </div>
                    </div>
                    <textarea
                      rows="3"
                      id="exampleForm.ControlTextarea1"
                      className="form-control"
                      spellcheck="false"
                      value={prompt}
                      onChange={(event) => setPrompt(event.target.value)}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <label className="form-label">
                      {t("Model")}
                      <OverlayTrigger
                        key="right"
                        placement="right"
                        overlay={<Tooltip>{t("model info")}</Tooltip>}
                      >
                        <span className="ms-2 text-primary">
                          <i class="fa-solid fa-circle-question"></i>
                        </span>
                      </OverlayTrigger>
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={model}
                      onChange={(event) => setModel(event.target.value)}
                    >
                      <option selected value="gpt-4o-mini">
                      gpt-4o-mini
                      </option>
                      {chatgpt.chatgpt4 === true ? (
                        <option value="gpt-4o">gpt-4</option>
                      ) : (
                        ""
                      )}
                       
                    </select>
                  </div>

                  <div className="form-group">
                    <label className="form-label">
                      {t("Temperature")}
                      <OverlayTrigger
                        key="right"
                        placement="right"
                        overlay={<Tooltip>{t("Temperature info")}</Tooltip>}
                      >
                        <span className="ms-2 text-primary">
                          <i class="fa-solid fa-circle-question"></i>
                        </span>
                      </OverlayTrigger>
                    </label>
                    <strong style={{ float: "right" }}>{temperature}</strong>
                    <input
                      type="range"
                      className="form-range"
                      min="0"
                      max="1"
                      step="0.1"
                      id="customRange"
                      value={temperature}
                      onChange={(e) => handleTemperatureChange(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      {t("Visibility")}
                      <OverlayTrigger
                        key="right"
                        placement="right"
                        overlay={<Tooltip>{t("Visibility info")}</Tooltip>}
                      >
                        <span className="ms-2 text-primary">
                          <i class="fa-solid fa-circle-question"></i>
                        </span>
                      </OverlayTrigger>
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={visibility}
                      onChange={(event) => setVisibility(event.target.value)}
                    >
                      <option value="public">{t("public")}</option>
                      <option value="private">{t("private")}</option>
                    </select>
                  </div>
                  {visibility && visibility === "private" && (
                    <div className="form-group">
                      <label className="form-label">
                        {t("Domains")}
                        <OverlayTrigger
                          key="right"
                          placement="right"
                          overlay={<Tooltip>{t("Domains info")}</Tooltip>}
                        >
                          <span className="ms-2 text-primary">
                            <i class="fa-solid fa-circle-question"></i>
                          </span>
                        </OverlayTrigger>
                      </label>
                      <textarea
                        rows="3"
                        id="exampleForm.ControlTextarea1"
                        className="form-control"
                        spellCheck="false"
                        value={domain}
                        onChange={(event) => {
                          setDomain(event.target.value);
                          setDomainError("");
                        }}
                      ></textarea>
                      <div className="text-danger">{domainError}</div>
                    </div>
                  )}

                  <div className="form-group">
                    <label className="form-label">
                      {t("Max Questions per visitor")}
                      <OverlayTrigger
                        key="right"
                        placement="right"
                        overlay={
                          <Tooltip>
                            {t("Max Questions per visitor info")}
                          </Tooltip>
                        }
                      >
                        <span className="ms-2 text-primary">
                          <i class="fa-solid fa-circle-question"></i>
                        </span>
                      </OverlayTrigger>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="messageCount"
                      id="messageCount"
                      value={messageCount}
                      onChange={(e) => setMessageCount(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      {t("Limit Message")}
                      <OverlayTrigger
                        key="right"
                        placement="right"
                        overlay={
                          <Tooltip>
                            {t(
                              "Please enter the message a user should receive if the message limit is reached"
                            )}
                          </Tooltip>
                        }
                      >
                        <span className="ms-2 text-primary">
                          <i class="fa-solid fa-circle-question"></i>
                        </span>
                      </OverlayTrigger>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      id="limit"
                      value={limitMessage}
                      onChange={(e) => setLimitMessage(e.target.value)}
                    />
                  </div>
                  
               
                  {/* <div className="form-group">
                    <label className="form-label">
                      {t("Message Limit (second)")}
                      <OverlayTrigger
                        key="right"
                        placement="right"
                        overlay={<Tooltip>{t("name info")}</Tooltip>}
                      >
                        <span className="ms-2 text-primary">
                          <i class="fa-solid fa-circle-question"></i>
                        </span>
                      </OverlayTrigger>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="messageLimit"
                      id="messageLimit"
                      value={messageLimit}
                      onChange={(e) => setMessageLimit(e.target.value)}
                    />
                  </div> */}

                  {setLoading ? (
                    <Loader />
                  ) : (
                    <input
                      type="button"
                      className="userbutton"
                      value={t("SUBMIT")}
                      onClick={(e) => updateBot(e)}
                    />
                  )}
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default UserChatBotSetting;
